<template>
  <div class="proxy">


    <!--  搜索工具  -->
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-input placeholder="输入 代(理商)运营商名称" size="mini" v-model="search_value" clearable></el-input>
      </div>
      <div class="crm-tool-item">
        <el-button size="mini" type="primary" @click="searchProxyOperation">搜索</el-button>
      </div>
      <div class="crm-tool-item-right">
        <el-button size="mini" type="primary" @click="createProxyOperation">创建代运营商</el-button>
      </div>
    </div>


    <!--  表格数据  -->
    <div class="crm-table">
      <el-table :data="tableData" border size="mini" style="width: 100%">
        <el-table-column prop="id" label="ID" width="140"></el-table-column>
        <el-table-column prop="enterprise_name" label="代(理商)运营商名称"></el-table-column>
        <el-table-column prop="login_phone" label="管理员" width="160"></el-table-column>
        <el-table-column prop="agent" label="代运营商" width="80"></el-table-column>
        <el-table-column prop="operator" label="代理商" width="80"></el-table-column>
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status_bool" @change="changeStatus(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="create_timestamp" label="创建时间" width="140"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="editAccount(scope.row,scope.$index)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="crm-footer">
      <el-pagination background :page-size="page_size" :current-page="page" layout="total,prev, pager, next"
                     @current-change="currentChange" :total="count"></el-pagination>
    </div>


    <div>
      <el-dialog :title="dialogCrateText" :visible.sync="dialogCrateVisible" :close-on-click-modal="false"
                 width="600px;">
        <div style="margin: 0 20px;">
          <el-form ref="form" :model="proxyOperationInfo" label-width="100px">
            <el-form-item label="商家名称">
              <el-input v-model="proxyOperationInfo.enterprise_name"></el-input>
            </el-form-item>
            <el-form-item label="管理员手机">
              <el-input v-model="proxyOperationInfo.login_phone"></el-input>
            </el-form-item>
            <el-form-item label="登录密码">
              <el-input v-model="proxyOperationInfo.password" type="password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" v-if="submit_status=='create'">
              <el-input type="password" v-model="proxyOperationInfo.checkPassword" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="角色">
              <el-checkbox v-model="proxyOperationInfo.agent_data" label="代运营商" border></el-checkbox>
              <el-checkbox v-model="proxyOperationInfo.operator_data" label="代理商" border></el-checkbox>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogCrateVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSubmitOperationInfo" :disabled="loading"
                     :loading="loading">{{ submitText }}</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {agent_account_list, agent_account_submit_info, agent_account_update_status} from "@/api/proxy/operation.js"
import preg_data from "@/utils/preg.js"

export default {
  name: "Manage",
  data() {
    return {
      search_value: "",
      page: 1,
      page_size: 20,
      count: 0,
      tableData: [],
      dialogCrateText: "创建运营商",
      dialogCrateVisible: false,
      proxyOperationInfo: {
        enterprise_name: "",
        username: "",
        password: "",
        checkPassword: "",
        agent_data: false,
        operator_data: false,
        id: 0,
        staff_id: 0
      },
      submit_status: "create",
      loading: false,
      submitText: "确 定",
      activeIndex: -1


    }
  },
  mounted() {
    this.$nextTick(function () {
      this.onLoadAccountList();
    })
  },
  methods: {
    currentChange(page) {
      this.page = page;
      this.onLoadAccountList();
    },
    onLoadAccountList() {
      let request_data = {};
      request_data.page = this.page;
      request_data.page_size = this.page_size;
      request_data.search_value = this.search_value;
      agent_account_list(request_data).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.count = res.data.count;
        }
      })
    },
    /**
     * 提交数据。
     */
    onSubmitOperationInfo() {
      let _this = this;

      if (this.proxyOperationInfo.enterprise_name == "") {
        this.$message.error("运营商名称不能为空");
        return false;
      }
      if (this.proxyOperationInfo.login_phone == "") {
        this.$message.error("员工登录手机号码不能为空");
        return false;
      }
      if (!preg_data.phone.test(this.proxyOperationInfo.login_phone)) {
        this.$message.error("员工登录手机格式不正确");
        return false;
      }
      if (this.submit_status == 'create' && this.proxyOperationInfo.password.length < 6) {
        this.$message.error("密码长度必须大于等于6位");
        return false;
      }

      if (this.submit_status == 'create') {
        if (this.proxyOperationInfo.password !== this.proxyOperationInfo.checkPassword) {
          this.$message.error("两次输入的密码不一致");
          return false;
        }
      }
      this.loading = true;
      this.submitText = "数据提交中....";
      agent_account_submit_info(this.proxyOperationInfo).then(res => {
        console.log(res);
        if (res.code == 0) {
          if (_this.proxyOperationInfo.staff_id == 0 && _this.proxyOperationInfo.id == 0) {
            _this.tableData.unshift(res.data);
          } else {
            _this.$set(_this.tableData, _this.activeIndex, res.data);
            _this.activeIndex = -1;
          }
          _this.loading = false;
          _this.submitText = "确定";
        }
        _this.dialogCrateVisible = false;
      })

    },
    createProxyOperation() {
      this.submit_status = "create";
      this.dialogCrateVisible = true;
      this.dialogCrateText = "创建运营商";
    },
    editAccount(row, index) {
      let data = JSON.parse(JSON.stringify(row));
      this.dialogCrateVisible = true;
      this.dialogCrateText = "修改运营商";
      data.password = "";
      data.checkPassword = "";
      data.operator_data = false;
      data.agent_data = false;
      if (data.operator == 'Y') {
        data.operator_data = true;
      }
      if (data.agent == 'Y') {
        data.agent_data = true;
      }
      this.proxyOperationInfo = data;
      this.activeIndex = index;
      this.submit_status = 'update';

    },
    changeStatus(row) {
      agent_account_update_status(row).then(res => {
        console.log(res);
      })
    },
    searchProxyOperation() {
      this.onLoadAccountList();
    }
  }
}
</script>

<style lang="scss" scoped>
.proxy {

  .crm-tool {
    height: 50px;
    line-height: 50px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 10px;
    }

    .crm-tool-item-right {
      height: 40px;
      line-height: 40px;
      float: right;
      margin: 10px 10px 5px 10px;
    }

  }

  .crm-table {
    padding: 10px;
  }

  .crm-footer {
    margin-top: 20px;
    text-align: right;
  }

}
</style>