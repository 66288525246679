<template>
  <div class="admin">
    <div class="header">
      <div class="logo">
        <img src="@/assets/logo.png"/>
      </div>
      <div class="nav">

        <div class="user-info">
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              <div class="user-info-btn">
                {{ userInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="userinfo">个人中心</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="admin-body">
      <div class="admin-menu">
        <el-menu default-active="2" class="el-menu-vertical-demo" @select="selectMenuItem">
          <el-submenu :index="index.toString()" :key="index" v-for="(item,index) in menuList">
            <template slot="title"><i :class="item.icon"></i><span>{{ item.label }}</span></template>
            <el-menu-item :index="index.toString()+'-'+sindex.toString()" :key="sindex"
                          v-for="(sitem,sindex) in item.children">
              {{ sitem.label }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="admin-content">
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: idnexPathUrl }" @click.native="toIndex">首页</el-breadcrumb-item>
            <el-breadcrumb-item :key="index" v-for="(item,index) in breadcrumb">{{ item }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <router-view/>
      </div>
    </div>


    <!-- 个人中心信息-->
    <div>
      <el-dialog title="个人中心" :visible.sync="dialogUserInfoVisible" width="800px">
        <div class="user-dialog">
          <div class="menu-nav-list">
            <div class="menu-nav-item" :key="index" :class="{active:index==userInfoMenuActiveIndex}"
                 v-for="(item,index) in userInfoMenuList" @click="selectUserItem(item,index)">{{ item.label }}
            </div>
          </div>
          <div class="menu-content">
            <component v-bind:is="currentTabComponent"></component>
          </div>
        </div>
      </el-dialog>
    </div>


  </div>
</template>

<script>
import userInfoComponent from "@/components/userinfo/userinfo";
import updatePasswdComponent from "@/components/userinfo/passwd";
import updateWechatComponent from "@/components/userinfo/wechat";

import {getUserinfo} from "../utils/auth";
import {auth_router_list} from "@/api/auth/auth.js"

export default {
  name: "Admin",
  components: {
    userInfoComponent,
    updatePasswdComponent,
    updateWechatComponent,
  },
  mounted() {
    this.$nextTick(function () {
      this.userInfo = getUserinfo();
      this.onLoadRouterAuthList();
    })
  },
  data() {
    return {
      userInfo: {},
      idnexPathUrl: "/admin",
      breadcrumb: ["控制台"],
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      menuList: [],
      dialogUserInfoVisible: false,
      userInfoMenuList: [
        {label: "个人信息", value: "userInfoComponent"},
        {label: "密码修改", value: "updatePasswdComponent"},
        {label: "微信登录", value: "updateWechatComponent"},
      ],
      userInfoMenuActiveIndex: 0,
      currentTabComponent: "userInfoComponent",
      dialogLogVisible: false,
    }
  },
  methods: {
    onLoadRouterAuthList() {
      auth_router_list().then(res => {
        if (res.code == 0) {
          this.menuList = res.data.authority_data;
        }
      })
    },
    toIndex() {
      console.log(12);
      this.idnexPathUrl = "/admin?url_token=" + Math.random();
      this.breadcrumb = ["控制台"];
    },
    selectMenuItem(item) {
      let arr = item.split("-");
      let parent = arr[0];
      let son = arr[1];
      let url = this.menuList[parent].children[son].role_url + "?url_token=" + Math.random();
      this.$router.push(url);
      this.breadcrumb = this.menuList[parent].children[son].breadcrumb
    },
    handleCommand(item) {
      switch (item) {
        case 'userinfo':
          this.dialogUserInfoVisible = true;
          break;
        case 'logout':
          this.$confirm('您确定要退出Crm系统的运营系统?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push("/login");
          }).catch(() => {
          });
          break;
      }
    },
    selectUserItem(item, index) {
      this.userInfoMenuActiveIndex = index;
      this.currentTabComponent = item.value;
    }
  }
}
</script>

<style lang="scss" scoped>
.admin {
  height: 100%;

  .header {
    height: 55px;
    background-color: #ffffff;
    width: 100%;
    border-bottom: 1px solid rgba(204, 204, 204, .16);
    box-shadow: 0px 1px 3px 0px rgba($color: #abcdef, $alpha: 0.4);
    min-width: 800px;

    .logo {
      float: left;

      img {
        width: 180px;
        margin-left: 20px;
      }
    }

    .nav {
      float: right;
      height: 55px;
      line-height: 55px;

      .user-log {
        float: left;
        width: 100px;
        font-size: 14px;
        cursor: pointer;
      }

      .user-info {
        float: left;
        width: 100px;
        font-size: 14px;

        .user-info-btn {
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
  }

  .admin-body {
    height: calc(100% - 60px);

    .admin-menu {
      height: 100%;
      float: left;
      border-right: 1px solid #d0d0d0;
      width: 200px;
    }

    .admin-content {
      float: left;
      width: calc(100% - 210px);

      .breadcrumb {
        padding-left: 10px;
        border-bottom: 1px solid #d2d2d2;

        .el-breadcrumb {
          height: 60px;
          line-height: 60px;
        }
      }

    }

  }

  .user-dialog {
    min-height: 500px;
    border-top: 1px solid #c7c7c7;

    .menu-nav-list {
      float: left;
      width: 140px;
      min-height: 500px;
      border-right: 1px solid #c7c7c7;
      padding-top: 10px;
      padding-left: 5px;
      padding-right: 5px;

      .menu-nav-item {
        width: 100%;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
      }

      .active {
        background-color: #409eff;
        color: #ffffff;
      }

      .menu-nav-item:hover {
        color: #409eff;
        background: #ecf5ff;
        border-color: #b3d8ff;
      }
    }

    .menu-content {
      float: left;
    }
  }

}
</style>