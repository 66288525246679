import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    redirect:"/"
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/Admin.vue'),
    children: [
      {path: "/admin/enterprise/account", component: () => import('@/page/enterprise/Account.vue')},
      {path: "/admin/enterprise/statistics", component: () => import('@/page/enterprise/Statistics.vue')},
      {path: "/admin/proxy/operation/manage", component: () => import('@/page/proxyOperation/Manage.vue')},
      {path: "/admin/service/node/datamanage", component: () => import('@/page/servieNode/DataManage.vue')},
      {path: "/admin/staff/manage", component: () => import('@/page/staff/Manage.vue')},
      {path: "/admin/staff/role", component: () => import('@/page/staff/Role.vue')},
      {path: "/admin/log/operation", component: () => import('@/page/log/Operation.vue')},
      {path: "/admin/log/service", component: () => import('@/page/log/Service.vue')},
    ],
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
