<template>
  <div class="app">
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-input v-model="searchData.search_value" size="mini" style="width: 320px;" placeholder="输入员工信息查询">
          <el-select v-model="searchData.search_type" slot="prepend" style="width: 100px;" placeholder="请选择">
            <el-option label="姓名" value="name"></el-option>
            <el-option label="登录手机" value="login_phone"></el-option>
          </el-select>
        </el-input>
      </div>
      <div class="crm-tool-item">
        <el-select v-model="searchData.status" size="mini" clearable placeholder="状态">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="crm-tool-item">
        <el-button size="mini" type="primary" @click="onSearch">搜索</el-button>
      </div>

      <div class="crm-tool-item-right">
        <el-button size="mini" type="primary" @click="createUpdateStaff">
          <i class="el-icon-circle-plus-outline"></i>添加员工信息
        </el-button>
      </div>
    </div>

    <div class="crm-table">
      <el-table :data="tableStaffData" size="mini" border style="width: 100%">
        <el-table-column prop="id" label="员工ID" width="100"></el-table-column>
        <el-table-column prop="name" label="员工姓名"></el-table-column>
        <el-table-column prop="login_phone" label="员工号码"></el-table-column>
        <el-table-column prop="bind_wechat" label="是否绑定微信"></el-table-column>
        <el-table-column prop="status" label="员工状态"></el-table-column>
        <el-table-column prop="role_name" label="员工角色"></el-table-column>
        <el-table-column prop="user_create_timestamp" label="创建时间"></el-table-column>
        <el-table-column prop="user_update_timestamp" label="修改时间"></el-table-column>
        <el-table-column prop="create_timestamp" width="160" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handlEditStaff(scope.row,scope.$index)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handlDangerStaff(scope.row,scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer">
      <el-pagination size="mini" background :page-size="page_size" layout="total, prev, pager, next"
                     @current-change="currentChange" :current-page="page" :total="count"></el-pagination>
    </div>


    <div class="crm-staff-update">
      <el-dialog :title="dialogUpdateStaffTitle" :close-on-click-modal="false" :visible.sync="dialogUpdateStaffVisible"
                 width="600px">
        <div style="width: 450px;margin: 0 50px;">
          <el-form ref="form" :model="staffInfo" label-width="80px">
            <el-form-item label="员工姓名">
              <el-input v-model="staffInfo.name"></el-input>
            </el-form-item>
            <el-form-item label="员工号码">
              <el-input v-model="staffInfo.login_phone"></el-input>
            </el-form-item>
            <el-form-item label="登录密码">
              <el-input v-model="staffInfo.passwd" type="password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" v-if="submitStats=='create'">
              <el-input type="password" v-model="staffInfo.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="员工角色">
              <el-select v-model="staffInfo.role_id" style="width: 100%;" placeholder="请选择">
                <el-option
                    v-for="item in role_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工状态">
              <el-radio-group v-model="staffInfo.status" size="medium">
                <el-radio border :label="item.value" :key="index" v-for="(item,index) in options">{{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogUpdateStaffVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSubmitStaffInfo">确 定</el-button>
        </span>
      </el-dialog>
    </div>

  </div>


</template>

<script>
import {
  staff_manage_list,
  staff_role_all_list,
  staff_manage_submit_staff,
  staff_manage_delete
} from "@/api/staff/manage.js"
import preg_data from "@/utils/preg.js"

export default {
  name: "staff_manage",
  data() {
    return {
      tableStaffData: [],
      count: 0,
      page: 1,
      page_size: 20,
      options: [
        {label: "在职", value: 'normal'},
        {label: "暂停", value: 'disable'},
        {label: "离职", value: 'resign'},
      ],
      searchData: {
        search_type: "name",
        search_value: "",
        status: 'normal'
      },
      dialogUpdateStaffVisible: false,
      dialogUpdateStaffTitle: "创建员工信息",
      staffInfo: {
        name: "",
        phone: "",
        login_phone: "",
        passwd: "",
        checkPass: "",
        status: 'normal',
        role_id: "",
        id: 0
      },
      activeIndex: -1,
      submitStats: "create",
      role_options: []
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.onLoadStaffManageList();
    })
  },
  methods: {


    /**
     * 显示编辑员工界面。
     */
    handlEditStaff(row, index) {
      this.onLoadAllRoleList();
      let data = JSON.parse(JSON.stringify(row));
      data.passwd = "";
      data.checkPass = "";
      this.staffInfo = data;
      this.submitStats = "update";
      this.dialogUpdateStaffVisible = true;
      this.activeIndex = index;
    },

    handlDangerStaff(row, index) {

      this.$confirm('此操作将永久删除该员工, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        staff_manage_delete(row).then(res=>{
          if(res.code==0){
            this.tableStaffData.splice(index,1);
            this.$message.success("删除成功");
          }
        })
      }).catch(() => {});

    },

    onSubmitStaffInfo() {
      console.log(this.staffInfo);
      if (this.staffInfo.name == "") {
        this.$message.error("员工姓名不能为空");
        return false;
      }
      if (this.staffInfo.login_phone == "") {
        this.$message.error("员工登录手机号码不能为空");
        return false;
      }
      if (!preg_data.phone.test(this.staffInfo.login_phone)) {
        this.$message.error("员工登录手机格式不正确");
        return false;
      }
      if (this.submitStats == 'create' && this.staffInfo.passwd.length < 6) {
        this.$message.error("密码长度必须大于等于6位");
        return false;
      }

      if (this.submitStats == 'create') {
        if (this.staffInfo.passwd !== this.staffInfo.checkPass) {
          this.$message.error("两次输入的密码不一致");
          return false;
        }
      }
      if (this.staffInfo.passwd.length > 0 && this.staffInfo.passwd.length < 6) {
        this.$message.error("密码长度必须大于等于6位");
        return false;
      }

      if (this.staffInfo.role_id <= 0) {
        this.$message.error("请选择员工的角色");
        return false;
      }
      staff_manage_submit_staff(this.staffInfo).then(res => {
        if (res.code == 0) {
          if (this.staffInfo.id <= 0) {
            this.tableStaffData.unshift(res.data);
            this.$message.success("员工添加成功");
          } else {
            this.$set(this.tableStaffData, this.activeIndex, res.data);
            this.$message.success("员工信息更新成功");
          }
          this.dialogUpdateStaffVisible = false;
        }
      });
    },


    onLoadAllRoleList() {
      staff_role_all_list().then(res => {
        if (res.code == 0) {
          this.role_options = res.data;
        }
      })
    },

    /**
     * 显示创建员工界面。
     */
    createUpdateStaff() {
      this.onLoadAllRoleList();
      this.submitStats = 'create';
      this.dialogUpdateStaffVisible = true;
      this.activeIndex = -1;
    },

    /**
     * 加载员工列表。
     */
    onLoadStaffManageList() {

      let search_data = this.searchData;
      search_data.page = this.page;
      search_data.page_size = this.page_size;

      staff_manage_list(search_data).then(res => {
        if (res.code == 0) {
          this.tableStaffData = res.data.data;
          this.count = res.data.count;
        }
      })

    },
    onSearch() {
      this.page = 1;
      this.onLoadStaffManageList();
    },
    currentChange(page) {
      this.page = page;
      this.onLoadStaffManageList();
    },

  }
}
</script>

<style lang="scss" scoped>

.app {

  .crm-tool {
    margin-top: 10px;
    height: 50px;
    line-height: 50px;
    padding-left: 10px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 0px;
    }

    .crm-tool-item-right {
      height: 40px;
      line-height: 40px;
      float: right;
      margin: 10px 10px 5px 0px;
    }

  }

  .crm-table {
    padding: 10px;
  }

  .footer {
    text-align: right;
    margin-top: 20px;
  }

  .crm-staff-update {

  }

}
</style>