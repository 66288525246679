import request from '@/utils/request'


/**
 * 操作日志列表
 * @param data
 * @returns {*}
 */
export function log_active_list(data) {
  return request({
    url: '/log/active/list',
    method: 'post',
    data
  })
}

/**
 * 筛选员工条件
 * @param data
 * @returns {*}
 */
export function log_active_staff_list() {
  return request({
    url: '/log/active/staff_list',
    method: 'get',
  })
}




/**
 * 操作日志列表
 * @param data
 * @returns {*}
 */
export function log_service_list(data) {
  return request({
    url: '/log/service/list',
    method: 'post',
    data
  })
}

