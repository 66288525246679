import request from '@/utils/request'


/**
 * 员工列表
 * @param data
 * @returns {*}
 */
export function enterprise_account_list(data) {
  return request({
    url: '/enterprise/account/list',
    method: 'post',
    data
  })
}



/**
 * 提价数据处理
 * @param data
 * @returns {*}
 */
export function enterprise_account_service_node_list() {
  return request({
    url: '/enterprise/account/service_node_list',
    method: 'get',
  })
}



/**
 * 创建企业信息。
 * @param data
 * @returns {*}
 */
export function enterprise_account_create_enterprise(data) {
  return request({
    url: '/enterprise/account/create_enterprise',
    method: 'post',
    data
  })
}


/**
 * 更新企业信息
 * @param data
 * @returns {*}
 */
export function enterprise_account_update_enterprise(data) {
  return request({
    url: '/enterprise/account/update_enterprise',
    method: 'post',
    data
  })
}



/**
 * 删除企业信息
 * @param data
 * @returns {*}
 */
export function enterprise_account_delete_enterprise(data) {
  return request({
    url: '/enterprise/account/delete_enterprise',
    method: 'post',
    data
  })
}



/**
 * 更改代理商的状态
 * @param data
 * @returns {*}
 */
export function agent_account_update_status(data) {
  return request({
    url: '/agent/account/update_status',
    method: 'post',
    data
  })
}

