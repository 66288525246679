import request from '@/utils/request'


/**
 * 员工列表
 * @param data
 * @returns {*}
 */
export function staff_manage_list(data) {
  return request({
    url: '/staff/manage/list',
    method: 'post',
    data
  })
}




/**
 * 提交员工信息。
 * @param data
 * @returns {*}
 */
export function staff_manage_submit_staff(data) {
  return request({
    url: '/staff/manage/submit_staff',
    method: 'post',
    data
  })
}




/**
 * 删除员工
 * @param data
 * @returns {*}
 */
export function staff_manage_delete(data) {
  return request({
    url: '/staff/manage/delete',
    method: 'post',
    data
  })
}



/**
 * 角色列表
 * @param data
 * @returns {*}
 */
export function staff_role_list(data) {
  return request({
    url: '/staff/role/list',
    method: 'post',
    data
  })
}


/**
 * 角色列表
 * @param data
 * @returns {*}
 */
export function staff_role_all_list() {
  return request({
    url: '/staff/role/all_list',
    method: 'get',
  })
}

/**
 * 角色列表
 * @param data
 * @returns {*}
 */
export function staff_role_authorize_tree() {
  return request({
    url: '/staff/role/authorize_tree',
    method: 'get',
  })
}



export function staff_role_submit_authorize(data) {
  return request({
    url: '/staff/role/submit_authorize',
    method: 'post',
    data
  })
}



export function staff_role_delete_role(data) {
  return request({
    url: '/staff/role/delete',
    method: 'post',
    data
  })
}