import axios from 'axios'

import {
  Message
} from 'element-ui'


import {
  getToken
} from '@/utils/auth'


const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// request interceptor
service.interceptors.request.use(
    config => {

      if (getToken()) {
        config.headers['authorization'] = getToken()
      }
      return config
    },
    error => {
      console.log(error) // for debug
      return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
      const res = response.data
      if (res.code !== 0) {

        Message({
          message: res.message,
          type: 'error',
          duration: 5 * 1000
        })

        //没有登录
        if (res.code == 4001) {
          setTimeout(function () {
            location.href = '/login';
          }, 2000)
          return;
        }

        //没有权限
        if (res.code == 4023) {
          setTimeout(function () {
            history.go(-1)
          }, 2000)
          return;
        }

        return res;
      } else {
        return res
      }
    },
    error => {
      console.log('err' + error) // for debug
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
      return {code: 50000, message: error};
      // return Promise.reject(error);
    }
)

export default service
