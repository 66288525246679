<template>
  <div class="app">


    <!--  搜索工具  -->
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-input placeholder="请输入商家名称" size="mini" v-model="searchData.input" clearable></el-input>
      </div>

      <div class="crm-tool-item">
        <el-select v-model="searchData.field" size="mini" placeholder="排序字段">
          <el-option
              v-for="item in optionsField"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="crm-tool-item">
        <el-select v-model="searchData.orderBy" size="mini" placeholder="排序方式">
          <el-option
              v-for="item in optionOrderBy"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="crm-tool-item">
        <el-button size="mini" type="primary">搜索</el-button>
      </div>


    </div>


    <!--  表格数据  -->
    <div class="crm-table">
      <el-table :data="tableData" border size="mini" style="width: 100%">
        <el-table-column prop="id" label="商家ID" width="140"></el-table-column>
        <el-table-column prop="name" label="商家名称"></el-table-column>
        <el-table-column prop="status_name" label="状态" width="80"></el-table-column>
        <el-table-column prop="status_name" label="线索数量" width="80"></el-table-column>
        <el-table-column prop="status_name" label="员工数量" width="80"></el-table-column>
        <el-table-column prop="auth_create_time" label="最后运营时间" width="140"></el-table-column>
        <el-table-column prop="auth_create_time" label="最后登录时间" width="140"></el-table-column>
        <el-table-column prop="auth_create_time" label="到期时间" width="140"></el-table-column>
      </el-table>
    </div>

    <div class="crm-footer">
      <el-pagination background layout="total,prev, pager, next" :total="1000"></el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "Statistics",
  data(){
    return {
      searchData:{
        field:"login_time",
        orderBy:"desc"
      },
      tableData:[],
      optionsField:[
        {label:"运营时间",value:"operation_time"},
        {label:"登录时间",value:"login_time"},
        {label:"到期时间",value:"use_time"}
      ],
      optionOrderBy:[
        {label:"倒序",value:"desc"},
        {label:"正序",value:"asc"}
      ],
    }
  }
}
</script>


<style lang="scss" scoped>

.app {
  .crm-tool {
    height: 50px;
    line-height: 50px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 10px;
    }

    .crm-tool-item-right {
      height: 40px;
      line-height: 40px;
      float: right;
      margin: 10px 10px 5px 10px;
    }

  }

  .crm-table {
    padding: 10px;
  }

  .crm-footer{
    margin-top: 20px;
    text-align: right;
  }

}

</style>