<template>
  <div class="service">

    <!--  搜索工具  -->
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-input placeholder="节点名称" size="mini" v-model="sarch_node_name" clearable></el-input>
      </div>
      <div class="crm-tool-item">
        <el-button size="mini" type="primary" @click="searchServiceNode">搜索</el-button>
      </div>
      <div class="crm-tool-item-right">
        <el-button size="mini" type="primary" @click="createServiceNode">添加服务器节点</el-button>
      </div>
    </div>


    <!--  表格数据  -->
    <div class="crm-table">
      <el-table :data="tableData" border size="mini" style="width: 100%">
        <el-table-column prop="serve_id" label="ID" width="140"></el-table-column>
        <el-table-column prop="service_name" label="服务器节点名称"></el-table-column>
        <el-table-column prop="service_connect" label="连接地址"></el-table-column>
        <el-table-column prop="service_username" label="登录账号"></el-table-column>
        <el-table-column prop="db_port" label="端口" width="100"></el-table-column>
        <el-table-column prop="service_area" label="地区" width="180"></el-table-column>
        <el-table-column prop="service_status" label="状态" width="100"></el-table-column>
        <el-table-column prop="deploy_number" label="已部署数量" width="100"></el-table-column>
        <el-table-column prop="service_create" label="创建时间" width="140"></el-table-column>
        <el-table-column prop="service_update" label="最后添加时间" width="140"></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="editServiceNodeInfo(scope.row,scope.$index)">编辑基本信息
            </el-button>
            <el-button type="text" size="mini" @click="editServiceNodeConnect(scope.row,scope.$index)">编辑连接信息
            </el-button>
            <el-button type="text" size="mini" @click="viewAccount(scope.row,scope.$index)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>


    <!--  分页  -->
    <div class="crm-footer">
      <el-pagination background :page-size="page_size" :current-page="page" layout="total, prev, pager, next"
                     @current-change="currentChange" :total="count"></el-pagination>
    </div>


    <!-- 增加服务器节点 -->
    <div>
      <el-dialog title="创建服务器节点" :close-on-click-modal="false" :visible.sync="dialogVisible" width="600px">
        <div style="margin: 0 20px;">
          <el-form ref="form" :model="serviceNodeData" label-width="100px">
            <el-form-item label="服务节点名称">
              <el-input v-model="serviceNodeData.service_name"></el-input>
            </el-form-item>
            <el-form-item label="连接地址">
              <el-input v-model="serviceNodeData.service_connect"></el-input>
            </el-form-item>
            <el-form-item label="服务节点地区">
              <el-input v-model="serviceNodeData.service_area"></el-input>
            </el-form-item>
            <el-form-item label="管理员账号">
              <el-input v-model="serviceNodeData.service_username"></el-input>
            </el-form-item>
            <el-form-item label="管理员密码">
              <el-input v-model="serviceNodeData.password" type="password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="端口号">
              <el-input v-model="serviceNodeData.db_port"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="success" @click="onSubmitServiceNode" v-if="testConnect">提交服务节点</el-button>
            <el-button type="primary" @click="onTestConnect" :disabled="loading" v-else>
              {{ testBtnText }} <i class="el-icon-loading" v-if="loading"></i>
            </el-button>
        </span>
      </el-dialog>
    </div>


    <!--修改节点信息-->

    <div>
      <el-dialog title="修改服务器节点信息" :close-on-click-modal="false" :visible.sync="dialogEditBaseInfoVisible"
                 width="600px">
        <div style="margin: 0 20px;">
          <el-form ref="form" :model="serviceNodeBaseInfo" label-width="100px">
            <el-form-item label="服务节点名称">
              <el-input v-model="serviceNodeBaseInfo.service_name"></el-input>
            </el-form-item>
            <el-form-item label="服务节点地区">
              <el-input v-model="serviceNodeBaseInfo.service_area"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogEditBaseInfoVisible = false">取 消</el-button>
            <el-button type="success" @click="onSubmitServiceNodeBaseInfo" :disabled="loading">提交服务节点</el-button>
        </span>
      </el-dialog>
    </div>


    <!--修改服务器连接信息-->
    <div>
      <el-dialog title="创建服务器节点" :close-on-click-modal="false" :before-close="handleConnectClose"
                 :visible.sync="dialogEditConnectVisible" width="600px">
        <div style="margin: 0 20px;">
          <el-form ref="form" :model="serviceNodeConnect" label-width="100px">
            <el-form-item label="连接地址">
              <el-input v-model="serviceNodeConnect.service_connect"></el-input>
            </el-form-item>
            <el-form-item label="管理员账号">
              <el-input v-model="serviceNodeConnect.service_username"></el-input>
            </el-form-item>
            <el-form-item label="管理员密码">
              <el-input v-model="serviceNodeConnect.password" type="password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="端口号">
              <el-input v-model="serviceNodeConnect.db_port"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogEditConnectVisible = false">取 消</el-button>
            <el-button type="success" @click="onSubmitServiceNodeConnect" v-if="testConnect">提交服务节点</el-button>
            <el-button type="primary" @click="onSubmitUpdateServiceNodeTest" :disabled="loading" v-else>
              {{ testBtnText }} <i class="el-icon-loading" v-if="loading"></i>
            </el-button>
        </span>
      </el-dialog>
    </div>


    <!--详情-->
    <el-dialog title="服务器详情" :visible.sync="dialogViewVisible" width="60%">
      <div>
        <el-descriptions class="margin-top" title="" :column="3" size="small" border>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i>数据库一</template>
            {{ serviceNodeInfo.service_name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-cpu"></i>服务器IP地址</template>
            {{ serviceNodeInfo.service_connect }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i>服务器地区</template>
            {{ serviceNodeInfo.service_area }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-connection"></i>部署数量</template>
            <el-tag size="small">{{ serviceNodeInfo.deploy_number }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-set-up"></i>状态</template>
            <div style="height: 20px;line-height: 20px;">
              <div style="float:left;display: inline;" v-if="serviceNodeInfo.service_status=='Y'">在线</div>
              <div style="float:left;display: inline;" v-else>离线</div>
              <div class="status" :class="StatusLight"></div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-timer"></i>创建时间</template>
            {{ serviceNodeInfo.service_create }}
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogViewVisible = false">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>

import {
  service_db_list,
  service_db_test,
  create_service_node,
  update_service_info,
  update_service_connect
} from "@/api/service/dbnode.js"

export default {
  name: "DataManage",
  data() {
    return {
      dialogVisible: false,
      sarch_node_name: "",
      page: 1,
      page_size: 20,
      tableData: [],
      count: 0,
      serviceNodeData: {
        service_name: "",
        service_connect: "",
        service_area: "",
        service_username: "",
        password: "",
        db_port: "",
      },
      testBtnText: "提交测试",
      testConnect: false,
      loading: false,
      dialogViewVisible: false,
      StatusLight: "status-info",
      activeIndex: -1,
      serviceNodeInfo: {},
      dialogEditBaseInfoVisible: false,
      serviceNodeBaseInfo: {
        service_name: "",
        service_area: "",
        serve_id: 0
      },
      dialogEditConnectVisible: false,
      serviceNodeConnect: {
        service_connect: "",
        service_username: "",
        password: "",
        db_port: "",
        serve_id: 0
      },
      onSubmitBtnText: "数据提交中..."
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.onLoadServiceNodeList();
    })
  },
  methods: {
    error() {
      this.StatusLight = "status-error";
    },
    success() {
      let i = 0;
      let _this = this;
      setInterval(function () {
        if (i >= 100) {
          i = 0;
        }
        if (i % 2 == 0) {
          _this.StatusLight = "status-success";
        } else {
          _this.StatusLight = "status-info";
        }
        i++;
      }, 200);
    },
    searchServiceNode() {
      this.onLoadServiceNodeList();
    },
    onLoadServiceNodeList() {
      let data = {};
      data.page = this.page;
      data.sarch_node_name = this.sarch_node_name;
      data.page_size = this.page_size;
      service_db_list(data).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.count = res.data.count;
        }
      })
    },
    onTestConnect() {

      let _this = this;

      if (this.serviceNodeData.service_connect == "") {
        this.$message.error("服务器连接地址不能为空");
        return false;
      }
      if (this.serviceNodeData.service_username == "") {
        this.$message.error("登录账号不能为空");
        return false;
      }
      if (this.serviceNodeData.password == "") {
        this.$message.error("登录密码不能为空");
        return false;
      }
      if (this.serviceNodeData.db_port == "" || this.serviceNodeData.db_port <= 1024) {
        this.$message.error("服务器开放端口不合法");
        return false;
      }

      _this.loading = true;
      _this.testBtnText = "连接服务器中";
      service_db_test(_this.serviceNodeData).then(res => {
        if (res.code == 0) {
          _this.testConnect = true;
        } else {
          _this.testConnect = false;
        }
        _this.loading = false;
        _this.testBtnText = "提交测试";
      })
    },
    onSubmitServiceNode() {
      if (this.serviceNodeData.service_name == "") {
        this.$message.error("服务器名称不能为空");
        return false;
      }
      if (this.serviceNodeData.service_connect == "") {
        this.$message.error("服务器连接地址不能为空");
        return false;
      }
      if (this.serviceNodeData.service_area == "") {
        this.$message.error("服务器所属区域不能为空");
        return false;
      }
      if (this.serviceNodeData.service_username == "") {
        this.$message.error("登录账号不能为空");
        return false;
      }
      if (this.serviceNodeData.password == "") {
        this.$message.error("登录密码不能为空");
        return false;
      }
      if (this.serviceNodeData.db_port == "" || this.serviceNodeData.db_port <= 1024) {
        this.$message.error("服务器开放端口不合法");
        return false;
      }
      create_service_node(this.serviceNodeData).then(res => {
        if (res.code == 0) {
          this.tableData.unshift(res.data);
          this.count++;
          this.dialogVisible = false;
          this.testBtnText = "提交测试";
          this.testConnect = false;
          this.loading = false;
        }
      })
    },
    createServiceNode() {
      this.dialogVisible = true;
      this.loading = false;
      this.testBtnText = "提交测试";
      this.testConnect = false;
      this.activeIndex = -1;
    },
    editServiceNodeInfo(row, index) {
      let data = JSON.parse(JSON.stringify(row));
      this.serviceNodeBaseInfo = data;
      this.dialogEditBaseInfoVisible = true;
      this.activeIndex = index;
    },
    onSubmitServiceNodeBaseInfo() {

      if (this.serviceNodeBaseInfo.service_name == "") {
        this.$message.error("服务器名称不能为空");
        return false;
      }
      if (this.serviceNodeBaseInfo.service_area == "") {
        this.$message.error("服务器所属区域不能为空");
        return false;
      }
      update_service_info(this.serviceNodeBaseInfo).then(res => {
        if (res.code == 0) {
          this.$set(this.tableData, this.activeIndex, res.data);
          this.dialogEditBaseInfoVisible = false;
        }
      });
    },
    editServiceNodeConnect(row, index) {
      console.log(row, index);
      let data = JSON.parse(JSON.stringify(row));
      this.serviceNodeConnect = data;
      this.dialogEditConnectVisible = true;
      this.activeIndex = index;
    },
    onSubmitUpdateServiceNodeTest() {
      let _this = this;
      if (this.serviceNodeConnect.service_connect == "") {
        this.$message.error("服务器连接地址不能为空");
        return false;
      }
      if (this.serviceNodeConnect.service_username == "") {
        this.$message.error("登录账号不能为空");
        return false;
      }
      if (this.serviceNodeConnect.password == "") {
        this.$message.error("登录密码不能为空");
        return false;
      }
      if (this.serviceNodeConnect.db_port == "" || this.serviceNodeConnect.db_port <= 1024) {
        this.$message.error("服务器开放端口不合法");
        return false;
      }
      _this.loading = true;
      _this.testBtnText = "连接服务器中";
      service_db_test(_this.serviceNodeConnect).then(res => {
        if (res.code == 0) {
          _this.testConnect = true;
        } else {
          _this.testConnect = false;
        }
        _this.loading = false;
        _this.testBtnText = "提交测试";
      })

    },
    onSubmitServiceNodeConnect(row, index) {
      update_service_connect(this.serviceNodeConnect).then(res => {
        if (res.code == 0) {
          this.$set(this.tableData, this.activeIndex, res.data);
          this.handleConnectClose();
        }
      })
      console.log(row, index);
    },
    viewAccount(row, index) {
      console.log(row, index);
      this.dialogViewVisible = true;
      this.serviceNodeInfo = row;
      if (this.serviceNodeInfo.service_status == 'Y') {
        this.success();
      } else {
        this.error();
      }

    },
    handleConnectClose() {
      this.loading = false;
      this.testConnect = false;
      this.testBtnText = "提交测试";
      this.dialogEditConnectVisible = false;
    },
    currentChange(page) {
      this.page = page;
      this.onLoadServiceNodeList();
    }
  }
}
</script>

<style lang="scss" scoped>
.service {
  .crm-tool {
    height: 50px;
    line-height: 50px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 10px;
    }

    .crm-tool-item-right {
      height: 40px;
      line-height: 40px;
      float: right;
      margin: 10px 10px 5px 10px;
    }

  }

  .crm-table {
    padding: 10px;
  }

  .crm-footer {
    margin-top: 20px;
    text-align: right;
  }
}

.status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  float: left;
  margin-left: 5px;
  margin-top: 4px;
}

.status-error {
  background-color: red;
}

.status-success {
  background-color: #67c23a;
}

.status-info {
  background-color: #ffffff;
}
</style>