<template>
  <div class="role">
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-input v-model="search_value" size="mini" style="width: 320px;" placeholder="输入角色名称"/>
      </div>

      <div class="crm-tool-item">
        <el-button size="mini" type="primary" @click="searchRole">搜索</el-button>
      </div>

      <div class="crm-tool-item-right">
        <el-button size="mini" type="primary" @click="createUpdateRole">
          <i class="el-icon-circle-plus-outline"></i>添加角色
        </el-button>
      </div>
    </div>

    <div class="crm-table">
      <el-table :data="tableRoleData" size="mini" border style="width: 100%">
        <el-table-column prop="role_id" label="ID" width="100"></el-table-column>
        <el-table-column prop="role_name" label="角色名称"></el-table-column>
        <el-table-column prop="role_status" label="角色状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status" style="color: #67c23a;">正常</span>
            <span v-else style="color: red;">暂停</span>
          </template>
        </el-table-column>
        <el-table-column prop="role_create" label="创建时间"></el-table-column>
        <el-table-column prop="role_update" label="修改时间"></el-table-column>
        <el-table-column width="120" label="操作">
          <template slot-scope="scope">
            <el-button @click="handleEditClick(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="handleDeleteClick(scope.row,scope.$index)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer">
      <el-pagination size="mini" background :page-size="page_size" :current-page="page"
                     @current-change="currentChange" layout="total, prev, pager, next" :total="count"></el-pagination>
    </div>

    <!--添加角色-->
    <div>
      <el-dialog title="添加角色" :visible.sync="dialogRoleVisible" width="600px">
        <div>
          <div style="width: 450px;margin: 0 50px;">
            <el-form ref="form" :model="roleInfo" label-width="80px">
              <el-form-item label="角色名称">
                <el-input v-model="roleInfo.role_name"></el-input>
              </el-form-item>
              <el-form-item label="角色权限">
                <el-tree :data="jurisdictionData" show-checkbox node-key="id" ref="tree"
                         :props="defaultProps"></el-tree>
              </el-form-item>
              <el-form-item label="角色状态">
                <el-switch v-model="roleInfo.status"></el-switch>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogRoleVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSubmitRoleData">确 定</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>

import {
  staff_role_list,
  staff_role_authorize_tree,
  staff_role_submit_authorize,
  staff_role_delete_role
} from "@/api/staff/manage.js";

export default {
  name: "Role",
  data() {
    return {
      page: 1,
      page_size: 20,
      search_value:"",
      tableRoleData: [{}],
      count: 0,
      dialogRoleVisible: false,
      roleInfo: {
        role_name: "",
        status: true,
        authority_data: [],
        authority_key: [],
        role_id: 0
      },
      jurisdictionData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.onLoadStaffRoleList();
      this.onLoadRoleAuthorize();
    })
  },
  methods: {

    /**
     * 点击搜索角色。
     */
    searchRole(){
      this.onLoadStaffRoleList();
    },
    /**
     * 显示创建角色的界面
     */
    createUpdateRole() {
      this.dialogRoleVisible = true;
      this.onLoadRoleAuthorize();
    },

    /**
     * 显示编辑界面弹窗
     */
    handleEditClick(row) {
      this.onLoadRoleAuthorize();
      this.dialogRoleVisible = true;
      this.roleInfo = row;
      this.$nextTick(function () {
        this.$refs.tree.setCheckedKeys(row.authority_key);
      })
    },

    /**
     * 删除角色。后端需要判断该角色下面是否有员工在使用。
     */
    handleDeleteClick(row, index) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        staff_role_delete_role(row).then(res=>{
          if(res.code==0){
            this.tableRoleData.splice(index,1);
            this.$message.success("删除成功");
          }
        })
      }).catch(() => {
      });
      console.log(row, index);
    },

    /**
     * 加载角色的表格列表。
     */
    onLoadStaffRoleList() {
      let search_data = {};
      search_data.page = this.page;
      search_data.page_size = this.page_size;
      search_data.search_value = this.search_value;
      staff_role_list(search_data).then(res => {
        if (res.code == 0) {
          this.tableRoleData = res.data.data;
          this.count = res.data.count;
        }
      })
    },

    /**
     * 分页。
     */
    currentChange(page) {
      this.page = page;
      this.onLoadStaffRoleList();
    },

    /**
     * 加载角色权限数据。
     */
    onLoadRoleAuthorize() {
      staff_role_authorize_tree().then(res => {
        if (res.code == 0) {
          this.jurisdictionData = res.data;
        }
      })
    },


    /**
     * 提交数据。
     */
    onSubmitRoleData() {
      let _this = this;
      this.roleInfo.authority_data = this.$refs.tree.getCheckedNodes();
      this.roleInfo.authority_key = this.$refs.tree.getCheckedKeys();
      staff_role_submit_authorize(this.roleInfo).then(res => {
        console.log(res);
        if (res.code == 0) {
          if (_this.roleInfo.role_id <= 0) {
            _this.tableRoleData.unshift(res.data);
            _this.count++;
          }
          _this.$message.success("数据提交成功");
          _this.dialogRoleVisible = false;
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.role {

  .crm-tool {
    margin-top: 10px;
    height: 50px;
    line-height: 50px;
    padding-left: 10px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 0px;
    }

    .crm-tool-item-right {
      height: 40px;
      line-height: 40px;
      float: right;
      margin: 10px 10px 5px 0px;
    }

  }

  .crm-table {
    padding: 10px;
  }

  .footer {
    text-align: right;
    margin-top: 20px;
  }

  .crm-staff-update {

  }
}
</style>