<template>
  <div class="app">

    <!--  搜索工具  -->
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-select v-model="search_staff_id" :clearable="true" size="mini" placeholder="请选择员工">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="crm-tool-item">
        <el-button size="mini" type="primary" @click="searchLogData">搜索</el-button>
      </div>

    </div>

    <div class="crm-table">
      <el-table :data="tableData" size="mini" border style="width: 100%">
        <el-table-column prop="log_create_date" label="日期" width="180"></el-table-column>
        <el-table-column prop="user_name" label="员工姓名" width="160"></el-table-column>
        <el-table-column prop="log_type_name" label="操作类型" width="140"></el-table-column>
        <el-table-column prop="log_content" label="操作内容"></el-table-column>
        <el-table-column prop="ip_address" label="IP地址" width="140"></el-table-column>
        <el-table-column prop="ip_area" label="IP地域" width="240"></el-table-column>
        <el-table-column prop="log_create_timestamp" label="操作时间" width="180"></el-table-column>
      </el-table>
    </div>
    <div class="crm-footer">
      <el-pagination background :page-size="page_size" :current-page="page" layout="total, prev, pager, next"
                     @current-change="currentChange" :total="count"></el-pagination>
    </div>
  </div>
</template>

<script>

import {log_active_list, log_active_staff_list} from "@/api/log/log.js"

export default {
  name: "operation",
  data() {
    return {
      page: 1,
      page_size: 20,
      search_staff_id: "",
      tableData: [],
      count: 0,
      options: []
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.onloadLogList();
      this.onUserStaffList();
    })
  },
  methods: {
    searchLogData() {
      this.onloadLogList();
    },
    onUserStaffList() {
      log_active_staff_list().then(res => {
        if (res.code == 0) {
          this.options = res.data;
        }
      })
    },
    onloadLogList() {
      let data = {};
      data.page = this.page;
      data.page_size = this.page_size;
      data.search_staff = this.search_staff_id;
      log_active_list(data).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.count = res.data.count;
        }
      })
    },
    currentChange(page) {
      this.page = page;
      this.onloadLogList();

    }
  }
}
</script>

<style lang="scss" scoped>
.app {
  .crm-tool {
    height: 50px;
    line-height: 50px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 10px;
    }

    .crm-tool-item-right {
      height: 40px;
      line-height: 40px;
      float: right;
      margin: 10px 10px 5px 10px;
    }

  }

  .crm-table {
    padding: 10px;
  }

  .crm-footer {
    margin-top: 20px;
    text-align: right;
  }

}
</style>