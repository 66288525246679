import request from '@/utils/request'


/**
 * 员工列表
 * @param data
 * @returns {*}
 */
export function service_db_list(data) {
  return request({
    url: '/service/node/list',
    method: 'post',
    data
  })
}

/**
 * 员工列表
 * @param data
 * @returns {*}
 */
export function service_db_test(data) {
  return request({
    url: '/service/node/test_connect',
    method: 'post',
    data
  })
}


/**
 * 创建服务节点
 * @param data
 * @returns {*}
 */
export function create_service_node(data) {
  return request({
    url: '/service/node/create_service_node',
    method: 'post',
    data
  })
}


/**
 * 更新服务节点基本信息
 * @param data
 * @returns {*}
 */
export function update_service_info(data) {
  return request({
    url: '/service/node/update_service_info',
    method: 'post',
    data
  })
}



/**
 * 更新服务节点链接信息
 * @param data
 * @returns {*}
 */
export function update_service_connect(data) {
  return request({
    url: '/service/node/update_service_connect',
    method: 'post',
    data
  })
}
