<template>
  <div class="app">


    <!--  搜索工具  -->
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-input placeholder="请输入商家名称" size="mini" v-model="search_value" clearable></el-input>
      </div>
      <div class="crm-tool-item">
        <el-button size="mini" type="primary" @click="onSearchData">搜索</el-button>
      </div>
      <div class="crm-tool-item-right">
        <el-button size="mini" type="primary" @click="createEnterprise">创建商家</el-button>
      </div>

    </div>


    <!--  表格数据  -->
    <div class="crm-table">
      <el-table :data="tableData" border size="mini" style="width: 100%">
        <el-table-column prop="enterprise_id" label="商家ID" width="140"></el-table-column>
        <el-table-column prop="login_id" label="登录ID" width="180"></el-table-column>
        <el-table-column prop="enterprise_name" label="商家名称"></el-table-column>
        <el-table-column prop="enterprise_status_name" label="状态" width="80"></el-table-column>
        <el-table-column prop="create_user_name" label="创建人" width="180"></el-table-column>
        <el-table-column prop="create_user_type" label="创建身份" width="180"></el-table-column>
        <el-table-column prop="enterprise_creatre_timestamp" label="创建时间" width="140"></el-table-column>
        <el-table-column prop="last_pay_date" label="付费时间" width="140"></el-table-column>
        <el-table-column prop="overdue_date" label="到期时间" width="140"></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editAccount(scope.row,scope.$index)">编辑信息</el-button>
            <el-button type="danger" size="mini" v-if="scope.row.enterprise_status_id == 'normal'"
                       @click="closeAccount(scope.row,scope.$index)">禁用
            </el-button>
            <el-button type="primary" size="mini" v-if="scope.row.enterprise_status_id == 'close'"
                       @click="openAccount(scope.row,scope.$index)">启用
            </el-button>
            <el-button type="danger" size="mini" v-if="scope.row.enterprise_status_id == 'ready'"
                       @click="deleteAccount(scope.row,scope.$index)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="crm-footer">
      <el-pagination background :page-size="page_size" :current-page="page" layout="total,prev, pager, next"
                     :total="count" @current-change="currentChange"></el-pagination>
    </div>




    <!-- 创建商家 -->
    <div>
      <el-dialog title="创建商家" :close-on-click-modal="false" :visible.sync="dialogEnterpriseVisible" width="600px">
        <div>
          <el-form ref="form" :model="enterpriseInfo" label-width="100px">
            <el-form-item label="商家名称">
              <el-input v-model="enterpriseInfo.enterprise_name"></el-input>
            </el-form-item>
            <el-form-item label="登录ID">
              <el-input v-model="enterpriseInfo.login_id"></el-input>
            </el-form-item>
            <el-form-item label="管理员账号">
              <el-input v-model="enterpriseInfo.login_phone"></el-input>
            </el-form-item>
            <el-form-item label="登录密码">
              <el-input v-model="enterpriseInfo.login_passwd" type="password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input type="password" v-model="enterpriseInfo.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="套餐">
              <el-select v-model="enterpriseInfo.combo" style="width: 100%;" placeholder="请选择">
                <el-option
                    v-for="item in date_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付金额">
              <el-input type="number" placeholder="支付单位(元)" v-model="enterpriseInfo.amount"
                        autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="数据库节点">
              <el-select v-model="enterpriseInfo.service_node_id" style="width: 100%;" placeholder="请选择">
                <el-option
                    v-for="item in service_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogEnterpriseVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSubmitCreateEnterprise">确 定</el-button>
        </span>
      </el-dialog>

    </div>


    <!--编辑信息-->
    <div>
      <el-dialog title="编辑信息" :close-on-click-modal="false" :visible.sync="dialogEditVisible" width="30%">
        <div>
          <el-form ref="form" :model="enterpriseEditInfo" label-width="100px">
            <el-form-item label="商家名称">
              <el-input v-model="enterpriseEditInfo.enterprise_name"></el-input>
            </el-form-item>
            <el-form-item label="登录ID">
              <el-input v-model="enterpriseEditInfo.login_id"></el-input>
            </el-form-item>
            <el-form-item label="管理员账号">
              <el-input v-model="enterpriseEditInfo.login_phone"></el-input>
            </el-form-item>
            <el-form-item label="登录密码">
              <el-input v-model="enterpriseEditInfo.login_passwd" placeholder="不修改留空" type="password"
                        autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input type="password" v-model="enterpriseEditInfo.checkPass" placeholder="不修改留空"
                        autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogEditVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSubmitUpdate">确 定</el-button>
        </span>
      </el-dialog>

    </div>

  </div>
</template>

<script>

import {
  enterprise_account_list,
  enterprise_account_service_node_list,
  enterprise_account_create_enterprise,
  enterprise_account_update_enterprise,
  enterprise_account_delete_enterprise
} from "@/api/enterprise/account.js"

export default {
  name: "list",
  data() {
    return {
      page: 1,
      page_size: 20,
      count: 0,
      search_value: "",
      tableData: [],
      dialogEnterpriseVisible: false,
      dialogEnterpriseEditVisible: false,
      enterpriseTitle: "创建商家",
      enterpriseInfo: {
        enterprise_name: "",
        login_id: "",
        login_phone: "",
        login_passwd: "",
        checkPass: "",
        combo: "",
        amount: 0.00,
        service_node_id: "",
      },
      enterpriseEditInfo: {
        enterprise_name: "",
        login_id: "",
        login_phone: "",
        login_passwd: "",
        checkPass: "",
      },
      date_options: [
        {label: "一年", value: "one_year"},
        {label: "二年", value: "tow_year"},
        {label: "三年", value: "three_year"},
      ],
      service_options: [],
      dialogEditVisible: false,
      activeIndex: -1,
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.onLoadServiceNodeList();
      this.onLoadAccountList();
    })
  },
  methods: {
    onLoadServiceNodeList() {
      enterprise_account_service_node_list().then(res => {
        if (res.code == 0) {
          this.service_options = res.data;
        }
      })
    },
    onLoadAccountList() {
      let data = {
        page: this.page,
        page_size: this.page_size,
        search_value: this.search_value
      }
      enterprise_account_list(data).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.count = res.data.count;
        }
      })
    },



    /**
     * 账号被手动禁用
     */
    closeAccount(row, index) {

      console.log(row, index);
      this.$confirm('此操作将会禁止商家说有账号登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '禁用成功!'
        });
        row.status_name = "已禁用";
        row.status = false;
      }).catch(() => {

      });
    },

    openAccount(row,index){
      console.log(row, index);
    },
    /**
     * 编辑账号基本信息
     */
    editAccount(row, index) {
      this.dialogEditVisible = true;
      this.activeIndex = index;
      let data = (JSON.parse(JSON.stringify(row)));
      data.login_passwd = "";
      data.checkPass = "";
      this.enterpriseEditInfo = data;
    },

    /**
     * 删除账号
     */
    deleteAccount(row,index){
      console.log(row,index);
      this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        enterprise_account_delete_enterprise({enterprise_id:row.enterprise_id}).then(res=>{
          if(res.code==0){
            this.tableData.splice(index,1);
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        })

      }).catch(() => { });
    },

    onSearchData() {
      this.onLoadAccountList();
    },
    /**
     * 创建商家。
     */
    createEnterprise() {
      this.dialogEnterpriseVisible = true;
      this.dialogEnterpriseEditVisible = true;
    },
    onSubmitCreateEnterprise() {
      if (this.enterpriseInfo.enterprise_name == "") {
        this.$message.error("商家名称不能为空");
        return false;
      }
      if (this.enterpriseInfo.login_id == "") {
        this.$message.error("登录ID不能为空");
        return false;
      }
      if (this.enterpriseInfo.login_phone == "") {
        this.$message.error("登录手机号码不能为空");
        return false;
      }
      if (this.enterpriseInfo.login_passwd.length < 6) {
        this.$message.error("登录密码长度不低于6位");
        return false;
      }
      if (this.enterpriseInfo.login_passwd !== this.enterpriseInfo.checkPass) {
        this.$message.error("两次密码不一致。");
        return false;
      }
      if (this.enterpriseInfo.combo == "") {
        this.$message.error("请选择商家套餐");
        return false;
      }
      if (this.enterpriseInfo.service_node_id == "") {
        this.$message.error("请选择服务器节点");
        return false;
      }
      enterprise_account_create_enterprise(this.enterpriseInfo).then(res => {
        if (res.code == 0) {
          this.tableData.unshift(res.data);
          this.dialogEnterpriseVisible = false;
        }
      })
    },
    currentChange(page) {
      this.page = page;
      this.onLoadAccountList();
    },
    onSubmitUpdate() {
      if (this.enterpriseEditInfo.enterprise_name == "") {
        this.$message.error("商家名称不能为空");
        return false;
      }
      if (this.enterpriseEditInfo.login_id == "") {
        this.$message.error("登录ID不能为空");
        return false;
      }
      if (this.enterpriseEditInfo.login_phone == "") {
        this.$message.error("登录手机号码不能为空");
        return false;
      }
      if (this.enterpriseEditInfo.login_passwd.length > 0) {
        if (this.enterpriseEditInfo.login_passwd.length < 6) {
          this.$message.error("登录密码长度不低于6位");
          return false;
        }
        if (this.enterpriseEditInfo.login_passwd !== this.enterpriseEditInfo.checkPass) {
          this.$message.error("两次密码不一致...");
          return false;
        }
      }
      enterprise_account_update_enterprise(this.enterpriseEditInfo).then(res => {
        if (res.code == 0) {
          this.$set(this.tableData, this.activeIndex, res.data);
          this.dialogEditVisible = false;
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>

.app {
  .crm-tool {
    height: 50px;
    line-height: 50px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 10px;
    }

    .crm-tool-item-right {
      height: 40px;
      line-height: 40px;
      float: right;
      margin: 10px 10px 5px 10px;
    }

  }

  .crm-table {
    padding: 10px;
  }

  .crm-footer {
    margin-top: 20px;
    text-align: right;
  }

}

</style>