import request from '@/utils/request'


/**
 * 员工列表
 * @param data
 * @returns {*}
 */
export function agent_account_list(data) {
  return request({
    url: '/agent/account/list',
    method: 'post',
    data
  })
}



/**
 * 提价数据处理
 * @param data
 * @returns {*}
 */
export function agent_account_submit_info(data) {
  return request({
    url: '/agent/account/submit_info',
    method: 'post',
    data
  })
}



/**
 * 更改代理商的状态
 * @param data
 * @returns {*}
 */
export function agent_account_update_status(data) {
  return request({
    url: '/agent/account/update_status',
    method: 'post',
    data
  })
}
