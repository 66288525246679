<template>
  <div class="app">

    <div class="crm-table">
      <el-table :data="tableData" size="mini" border style="width: 100%">
        <el-table-column prop="date" label="操作时间" width="180"></el-table-column>
        <el-table-column prop="user_name" label="员工姓名" width="160"></el-table-column>
        <el-table-column prop="service_name" label="服务器名称" width="140"></el-table-column>
        <el-table-column prop="service_id" label="服务器ID" width="140"></el-table-column>
        <el-table-column prop="log_type_name" label="操作类型" width="140"></el-table-column>
        <el-table-column prop="log_content" label="操作内容"></el-table-column>
        <el-table-column prop="address" label="详情" width="180">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="ViewInfo(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination background :page-size="page_size" :current-page="page" layout="total, prev, pager, next"
                     @current-change="currentChange" :total="count"></el-pagination>
    </div>
  </div>
</template>

<script>
import {log_service_list} from "@/api/log/log.js"

export default {
  name: "Service",
  data() {
    return {
      tableData: [],
      count: 0,
      page: 1,
      page_size: 20,
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.onLoadServiceLogList();
    })
  },
  methods: {
    currentChange(page) {
      this.page = page;
      this.onLoadServiceLogList();
    },
    onLoadServiceLogList() {
      let data = {};
      data.page = this.page;
      data.page_size = this.page_size;
      log_service_list(data).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.count = res.data.count;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app {
  .crm-table {
    margin: 10px;
  }

  .footer {
    text-align: right;
    margin-top: 20px;
  }
}
</style>